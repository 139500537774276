import { PropsWithRef, useMemo } from 'react';

import _ from 'lodash';
import { formatShortUUid } from '../utilities';
import {
  CheckboxGroupEntry,
  CheckboxGroup,
} from '../../../components/CheckboxGroup';

export interface UsersProps {
  userIds: string[];

  selectedUserIds: Set<string>;
  setSelectedUserIds: (value: Set<string>) => void;
}

export function Users({
  userIds,
  selectedUserIds,
  setSelectedUserIds,
}: PropsWithRef<UsersProps>): JSX.Element {
  const values = useMemo<CheckboxGroupEntry[]>(
    () =>
      _.map(userIds, (name: string) => ({
        id: name,
        label: formatShortUUid(name),
      })),
    [userIds]
  );

  return (
    <CheckboxGroup
      titleId="content.metrics.filters.users.title"
      allId="content.metrics.filters.users.all"
      values={values}
      selectedValues={selectedUserIds}
      setSelectedValues={setSelectedUserIds}
    />
  );
}
