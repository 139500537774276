import { PropsWithRef, useMemo } from 'react';

import _ from 'lodash';
import { formatShortUUid } from '../utilities';
import {
  CheckboxGroupEntry,
  CheckboxGroup,
} from '../../../components/CheckboxGroup';

export interface SessionsProps {
  sessionIds: string[];

  selectedSessionIds: Set<string>;
  setSelectedSessionIds: (value: Set<string>) => void;
}

export function Sessions({
  sessionIds,
  selectedSessionIds,
  setSelectedSessionIds,
}: PropsWithRef<SessionsProps>): JSX.Element {
  const values = useMemo<CheckboxGroupEntry[]>(
    () =>
      _.map(sessionIds, (name: string) => ({
        id: name,
        label: formatShortUUid(name),
      })),
    [sessionIds]
  );

  return (
    <CheckboxGroup
      titleId="content.metrics.filters.sessions.title"
      allId="content.metrics.filters.sessions.all"
      values={values}
      selectedValues={selectedSessionIds}
      setSelectedValues={setSelectedSessionIds}
    />
  );
}
