const styleOverrides = {
    root: {
        maxWidth: '80%',
        '& .MuiAlert-root': {
            maxWidth: '80%',
            alignItems: 'center',
        },
    },
};
const defaultProps = {};
export const MuiSnackbar = {
    styleOverrides,
    defaultProps,
};
