import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, IconButton, Snackbar, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createContext, useCallback, useState, } from 'react';
export const MessageBarContext = createContext({
    openMessageBar: () => { },
    closeMessageBar: () => { },
});
function MessageBar({ open, message, options, alertOptions, onClose, }) {
    return (_jsx(Snackbar, Object.assign({ anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        } }, options, { onClose: onClose, open: open, message: message }, { children: _jsx(Alert, Object.assign({ severity: "info", action: _jsx(IconButton, Object.assign({ onClick: onClose }, { children: _jsx(CloseIcon, {}) })) }, alertOptions, { children: message })) })));
}
export function MessageBarProvider({ children, }) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState();
    const [options, setOptions] = useState();
    const [alertOptions, setAlertOptions] = useState();
    const closeMessageBar = useCallback(() => {
        setOpen(false);
        setMessage(undefined);
        setOptions(undefined);
        setAlertOptions(undefined);
    }, []);
    const openMessageBar = useCallback((openP, messageP, optionsP, alertOptionsP) => {
        setOpen(openP);
        setMessage(messageP);
        setOptions(optionsP);
        setAlertOptions(alertOptionsP);
    }, []);
    const [value] = useState({
        openMessageBar,
        closeMessageBar,
    });
    return (_jsxs(MessageBarContext.Provider, Object.assign({ value: value }, { children: [_jsx(MessageBar, { open: open, message: message, options: options, alertOptions: alertOptions, onClose: (options === null || options === void 0 ? void 0 : options.onClose) || (alertOptions === null || alertOptions === void 0 ? void 0 : alertOptions.onClose) || closeMessageBar }), children] })));
}
