import { COLOURS } from './colours';
import { fontFamily, primary } from './constants';
// #region MuiDialog
const dialogStyleOverrides = {
    root: {
    // Nothing for now
    },
    paper: {
        width: 'unset',
    },
};
export const MuiDialog = {
    styleOverrides: dialogStyleOverrides,
};
// #endregion
// #region MuiDialogTitle
const titleStyleOverrides = {
    root: {
        backgroundColor: primary.main,
        color: primary.contrastText,
    },
};
export const MuiDialogTitle = {
    styleOverrides: titleStyleOverrides,
};
// #endregion
// #region MuiDialogActions
const actionStyleOverrides = {
    root: {
        backgroundColor: primary.dark,
        color: primary.contrastText,
        '& .MuiButton-root': {
            color: primary.contrastText,
            '&:disabled': {
                color: COLOURS.Disabled,
            },
        },
        '.step-dialog &': {
            justifyContent: 'space-between',
        },
    },
};
export const MuiDialogActions = {
    styleOverrides: actionStyleOverrides,
};
// #endregion
// #region MuiDialogContent
const contentStyleOverrides = {
    root: Object.assign({}, fontFamily),
};
export const MuiDialogContent = {
    styleOverrides: contentStyleOverrides,
};
// #endregion
