import moment from 'moment';
import { createContext, useMemo } from 'react';

export interface FilterContextProps {
  search: string;
  appNames: Set<string>;
  userIds: Set<string>;
  sessionIds: Set<string>;
  eventIds: Set<string>;
  startTime: moment.Moment;
  endTime: moment.Moment;
}

export const FilterContext = createContext<FilterContextProps>({
  search: '',
  appNames: new Set(),
  userIds: new Set(),
  sessionIds: new Set(),
  eventIds: new Set(),
  startTime: moment().startOf('day'),
  endTime: moment().endOf('day'),
});

export function FilterContextProvider({
  children,
  search,
  appNames,
  userIds,
  sessionIds,
  eventIds,
  startTime,
  endTime,
}: FilterContextProps & {
  children: null | JSX.Element;
}) {
  const value = useMemo<FilterContextProps>(
    () => ({
      search,
      appNames,
      userIds,
      sessionIds,
      eventIds,
      startTime,
      endTime,
    }),
    [search, appNames, eventIds, sessionIds, userIds, startTime, endTime]
  );

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
}
