import { Stack, Typography } from '@mui/material';
import { Event } from 'hive-analytics-react';
import { PropsWithRef, useMemo } from 'react';
import _ from 'lodash';

interface EventDataItemProps {
  label: string;
  value: any;
}

function EventDataItem({
  label,
  value,
}: PropsWithRef<EventDataItemProps>): JSX.Element {
  const formattedValue = useMemo(() => {
    if (_.isString(value)) {
      return value;
    }

    const status = _.get(value, 'error.status');
    const message = _.get(value, 'error.content.message');
    if (status || message) {
      return _.join(_.compact([status, value]), ' ');
    }

    return JSON.stringify(value);
  }, [value]);
  return (
    <Stack key={label} direction="row">
      <Typography width="100px" variant="body1">
        {label}
      </Typography>
      <Typography flexGrow={1} variant="body2">
        {formattedValue}
      </Typography>
    </Stack>
  );
}

export interface EventDataProps {
  event: Event;
}

export function EventData({
  event,
}: PropsWithRef<EventDataProps>): JSX.Element {
  return (
    <Stack spacing={4}>
      {_.map(event.properties.data, (value: any, label: string) => (
        <EventDataItem key={label} label={label} value={value} />
      ))}
    </Stack>
  );
}
