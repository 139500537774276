import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

export const useSelectedValues = (
  initialValues: string[]
): [Set<string>, (value: Set<string>) => void] => {
  const [selectedValues, setSelectedValues] = useState<Set<string>>(
    new Set<string>()
  );

  const initialized = useRef(false);
  useEffect(() => {
    if (initialized.current || _.isEmpty(initialValues)) {
      return;
    }

    setSelectedValues(new Set<string>(initialValues));
    initialized.current = true;
  }, [initialValues]);

  return [selectedValues, setSelectedValues];
};
